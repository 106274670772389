$colorGreen : #95C11F;
$colorSimpleGreen : #ECF4D8;
$colorBlue : #00A5C8;
$colorSimpleBlue : #D9F2F7;
$colorGray : #627171;
$colorSimpleGray : #B7B7B7;
$colorBlack : #000000;
$colorWhite:#FFFFFF;

.tx_green{
    color: $colorGreen;
}
.box-intro-mantencion {
    h2 {
        color: #00A5C8;
        font-weight: 500 !important;
        font-size: 23px;
        text-transform: unset;
        margin-bottom: 25px;
    }

    p {
        font-size: 14px;
        min-height: 90px;
    }

    button {
        padding: 10px 15px;
        width: 100%;
        background-color: transparent;
        border-radius: 5px;
        font-weight: bold;

        &.blue {
            border: solid 1px #00A5C8;
            color: #00A5C8;
            &.active{
                background-color: #00A5C8;
                color: #fff;
            }

        }

        &.green {
            border: solid 1px #95C11F;
            color: #95C11F;
            &.active{
                background-color: #95C11F;
                color: #fff;
            }
        }
    }
}